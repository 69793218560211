<template>
    <div id="team" class="rn-new-items rn-section-gapTop">
        <div class="container">
            <div class="row mb--50 align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <h3 class="title mb--0" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        {{app_static.team.title}}
                    </h3>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
                    <div class="view-more-btn text-start text-sm-end">
                        <router-link class="btn-transparent" to="/product" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            VIEW ALL <i class="feather-arrow-right"/>
                        </router-link>
                    </div>
                </div> -->
            </div>
            <div class="row g-5">
                <div v-show="true" class="col-5 col-lg-4 col-md-6 col-sm-6 col-12"
                     v-for="(item, index) in members()"
                     :key="'members-item-'+index"
                     data-sal=""
                     :data-sal-delay="150+index"
                     data-sal-duration="800">
                    <team-card
                        :product-date="item"
                        product-style-class="no-overlay"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import ProductCard from '@/components/product/ProductCard'
    import TeamCard  from '@/components/app/TeamCard'
    import store from '@/store/index'
    // import data_api from "@/libs/data_api";

    import { get,sync } from "vuex-pathify";
    import app_static from '@/app_static.env'
    
    import utils from "@/libs/utils"

    export default {
        name: 'ExploreNewestItem',
        components: {
            // ProductCard,
            TeamCard
        },
        computed: {
            team: sync('team/list'),
        }, 
        mounted() {
        }, 
        methods: {
            
            members() {
                // return this.team;
                // utils.console.log("sore team list: ", store.state.team.list);
                if (store.state.team && store.state.team.list 
                && store.state.team.list.length > 0) {
                    // return store.state.team.list;
                    return store.state.team.list.sort(function(a, b) {
                        if(a.order_number < b.order_number) { return -1; }
                        if(a.order_number > b.order_number) { return 1; }
                        return 0;
                    })
                } else {
                    return [];
                }
            }
        },
        data() {
            return {
                app_static: app_static,
            }
        }
    }
</script>