<template>
    <div class="mt--80 ">

        <div class="video_banner_wide" style="position: relative; padding-top: 30.694444444444446%;">
            <iframe :src="wide_src" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="false">
            </iframe>
        </div>

        <div class="video_banner_mobile" style="position: relative; padding-top: 100%;">
            <iframe :src="mobile_src" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="false">
            </iframe>
        </div>

    </div>
</template>

<script>
import app_static from '@/app_static.env';
import { required } from '@vuelidate/validators';

export default {
    name: 'VideoBanner',
    props: {
    },
    components: {},
    data() {
        return {
            //  1440 x 442 px.mp4
            // wide_src: 'https://customer-2jo4u9a2zwx7dqbv.cloudflarestream.com/4ac92bb93a6131cbd0f38a843b0fbe56/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-2jo4u9a2zwx7dqbv.cloudflarestream.com%2F4ac92bb93a6131cbd0f38a843b0fbe56%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false',

            // genesis_banner.mp4
            // wide_src: 'https://customer-2jo4u9a2zwx7dqbv.cloudflarestream.com/184c60b0050fe3b3d653ff1e9ae90166/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-2jo4u9a2zwx7dqbv.cloudflarestream.com%2F184c60b0050fe3b3d653ff1e9ae90166%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false',
            wide_src: 'https://customer-2jo4u9a2zwx7dqbv.cloudflarestream.com/7e5a89d25b1cd42691ccaeecca3e4aff/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-2jo4u9a2zwx7dqbv.cloudflarestream.com%2F7e5a89d25b1cd42691ccaeecca3e4aff%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false',
            mobile_src: 'https://customer-2jo4u9a2zwx7dqbv.cloudflarestream.com/117151168c50ea117c0171cf1ff9dc5d/iframe?muted=true&preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-2jo4u9a2zwx7dqbv.cloudflarestream.com%2F117151168c50ea117c0171cf1ff9dc5d%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600&controls=false',

            // video_banner: app_static.genesis_banner,
            // video_banner_mob: app_static.genesis_banner_mob,
            
            // video_banner: require('@/assets/videos/genesis_banner.mp4'),
            // video_banner_mob: require('@/assets/videos/genesis_banner_mob.mp4'),
        }
    }
}
</script>