<template>
    <div id="partnerslogos" class="rn-new-items rn-section-gapTop">

      <div class="row">  
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 d-flex justify-content-start"
        v-for="(item, index) in members()"
        :key="'productlogo-'+index"
        >
          <div class="partner_logo_img">
              <a :href="item.url_text" target="_blank">
                  <img class="" :src="item.logo_image" :alt="item.name_text" @load="$emit('handleImageLoad')">
              </a>
          </div>
      </div>
    </div>
    </div>
</template>

<script>
    // import ProductCard from '@/components/product/ProductCard'
    // import NftCard  from '@/components/app/NftCard'
    import store from '@/store/index'

    import { get,sync } from "vuex-pathify";
    import app_static from '@/app_static.env'
    
    import utils from "@/libs/utils"

    export default {
        name: 'PartnerLogos',
        components: {
            
        },
        computed: {
            partner: sync('partner/list'),
        }, 
        mounted() {
        }, 
        methods: {
            
            members() {
                // return this.team;
                // utils.console.log("store partner list: ", store.state.partner.list);
                if (store.state.partner && store.state.partner.list 
                && store.state.partner.list.length > 0) {
                    return store.state.partner.list;
                    // return store.state.partner.list.sort(function(a, b) {
                    //     if(a.promo_priority_number < b.promo_priority_number) { return -1; }
                    //     if(a.promo_priority_number > b.promo_priority_number) { return 1; }
                    //     return 0;
                    // })
                } else {
                    return [];
                }
            }
        },
        data() {
            return {
                app_static: app_static,
            }
        }
    }
</script>