<template>
    <layout>
        <breadcrumb title="Creator Registration" current="Creator Registration" />

        <div class="login-area message-area mt--20 mb--100">
            <div class="container">
                <div class="row g-5  ">
                    <!-- d-flex justify-content-center 
                    <div style="max-width: 1000px">
                        <div id="referralhero-signup-widget"></div>
                    </div> -->
                    <div class="show_small_screen col-md-6 ml--10 mr--10">
                        <h3>{{ title }}</h3>
                        <p v-for="(para, index) in paras_mob" :key="index">
                            {{ para }}
                        </p>
                    </div>

                    <div class="col-md-6">
                        <div id="referralhero-signup-widget"></div>
                    </div>
                    <div class="hide_small_screen col-md-6">
                        <h3>{{ title }}</h3>
                        <p v-for="(para, index) in paras" :key="index">
                            {{ para }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </layout>
</template>


<style>
#mtr-optin-form {
    width: 100% !important;
}
</style>

<script>

import Layout from "@/components/layouts/Layout";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
import ContactForm from "@/components/contact/ContactForm";

export default {
    name: 'Contact',
    components: { ContactForm, Breadcrumb, Layout },
    mixins: [SalScrollAnimationMixin],
    data() {
        return {
            bannerImg: require('@/assets/images/banner/wellness_activated.png'),
            title: "Empower with The Blisspot Genesis Collection",
            paras: [
                "Blisspot is excited to announce the launch of its new Genesis NFT Collection in the Web3 space, designed to help individuals enhance their wellbeing and boost their energy levels through world-class content.",
                "We would greatly appreciate your support in promoting this opportunity to your audience. By signing up for the program, you will receive a unique affiliate link that will track all sales generated through your promotion. Blisspot will then reward you with a 20% revenue for every sale made through your link.",
                "Join us in supporting wellbeing and promoting the innovative use of NFTs. Sign up for the Blisspot Genesis NFT collection program today and start earning a commission while empowering others to prioritize their health and happiness."
            ],
            paras_mob: [
                "Introducing Blisspot's Genesis NFT Collection in the Web3 space! Enhance your wellbeing with premium content and raise your energy levels to new heights. Join our program, get a unique affiliate link, and earn a 20% revenue on every sale made through your link. Support the innovative use of NFTs while empowering others to prioritize their health and happiness. Sign up for the Blisspot Genesis NFT collection program today and start promoting wellness while earning a commission.",
            ]
        }
    },
}

</script>


