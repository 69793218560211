<template>
    <div id="collection" class="rn-new-items rn-section-gapTop">
        <div class="container">
            <div class="row mb--50 align-items-center">
                <div class="col-lg-9 col-md-6 col-sm-6 col-12">
                    <h3 class="title mb--0" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        {{app_static.nft.title}}
                    </h3>
                </div>
            </div>
            <div class="row g-5">
                <div v-show="true" class="col-12"
                     v-for="(item, index) in members()"
                     :key="'members-item-'+index"
                     data-sal=""
                     :data-sal-delay="150+index"
                     data-sal-duration="800">
                    <nft-card
                        :product-date="item"
                        product-style-class="no-overlay"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import ProductCard from '@/components/product/ProductCard'
    import NftCard  from '@/components/app/NftCard'
    import store from '@/store/index'
    import data_api from "@/libs/data_api";

    import { get,sync } from "vuex-pathify";
    import app_static from '@/app_static.env'
    
    import utils from "@/libs/utils"

    export default {
        name: 'Nft',
        components: {
            NftCard
        },
        computed: {
            nft: sync('nft/list'),
        }, 
        mounted() {
        }, 
        methods: {
            
            members() {
                // return this.team;
                // utils.console.log("store nft list: ", store.state.nft.list);
                if (store.state.nft && store.state.nft.list 
                && store.state.nft.list.length > 0) {
                    // return store.state.nft.list;
                    return store.state.nft.list.sort(function(a, b) {
                        if(a.promo_priority_number < b.promo_priority_number) { return -1; }
                        if(a.promo_priority_number > b.promo_priority_number) { return 1; }
                        return 0;
                    })
                } else {
                    return [];
                }
            }
        },
        data() {
            return {
                app_static: app_static,
            }
        }
    }
</script>