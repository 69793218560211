import data_api from "../libs/data_api";
import store from '@/store/index'
import utils from "./utils"

data_api.init();

export const init = () => {
    data_api.team.index()
    .then((resp) => {
    //   utils.console.log("team index resp: ", resp);
    //   utils.console.log("team index resp results: ", resp.data.data.response);
      // // set store leases data
      // store.state.leases.list.concat(resp.data);
      store.set("team/list", resp);
      // utils.console.log("store team list fater init: ", store.state.team.list);
    })
    .catch((error) => {
      console.error("team index faild: ", error)
    });

}

