<template>
    <div class='lg-product-wrapper product-list-card'>
        <div class="inner">

            <div class="row">
                <div class="col-12 col-md-4 col-lg-3 ">
                    <img class="img_rounded_raised" :src="productDate.logo_image" :alt="productDate.promo_title_text" @load="$emit('handleImageLoad')">        
                </div>

                <div class="col-12 col-md-8 col-lg-9 ">
                    <div class="row">
                        <div class="col-12 col-md-12 col-md-7 col-lg-7">
                            <div class="read-content flex align-items-stretch">
                                <div class="product-share-wrapper">
                                    <div class="h2 mb--5">
                                        {{ productDate.promo_title_text}}
                                    </div>
                                </div>
                                <div class="d4 pb--20">{{ productDate.long_desc_text }}</div>
                            </div>   
                        </div>

                        <div class="col-12 col-md-12 col-md-5 col-lg-5">
                    
                            <h2 class="">
                                {{ app_static.nft.tokenomics_title }}
                            </h2>
                            <div class="content">
                                <div class="tokenomics_item d-flex justify-content-left"
                                    v-for="(item, index) in productDate.tokenomics_items_list_text" 
                                    :key="'checkbox' + index"
                                    >
                                    <i class="feather-check-square color-primary mr--10 "/>
        
                                    <label for="level-check0">
                                        {{item}}
                                    </label>
                                </div>
        
                            </div>
        
                        </div>
                    </div>

                
                    <div class="row">
                        <a type="button"
                        class="read-content btn btn-primary mr--30 mt--10"
                        :href="app_static.opensea_genesis"
                        target="_blank">
                        {{app_static.c2a_buy_on_opensea}}
                    </a>
                    </div>
                
                </div>
            </div>
        

        </div>
    </div>
</template>

<style lang="scss">
.nft_img_min_width {
    min-width: 300px;
}
</style>

<script>
    import Countdown from '@/components/product/Countdown'
    import app_static from '@/app_static.env'

    export default {
        name: 'NftCard',
        components: {Countdown},
        props: {
            productDate: {},
            productStyleClass: {
                type: String
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            },
            showTwoColumn: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                app_static: app_static,
            }
        }
    }
</script>