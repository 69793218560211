import {createApp} from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'


// 3rd party plugins
import 'axios'
import Notifications from '@kyvg/vue3-notification'

import 'animate.css'
import './assets/css/feature.css'
import './assets/scss/style.scss'
import 'bootstrap'

createApp(App)
    .use(Notifications)
    .use(Vuex)
    .use(router)
    .use(store)
    .mount('#app')
