<template>
    <div id="about" class="">

        <!-- Start CTA Area -->
        <!-- <div class="rn-callto-action">
            <div class="container-fluid about-fluidimg-cta"> -->
        <div class="">
            <div class="">
        
                <div class="row">
                    <div class="col-lg-12">
                        <div class="bg_image--appintro bg_image bg-image-border" data-black-overlay="7">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="call-to-action-wrapper">
                                        <h3 data-sal="slide-up" data-sal-duration="800" data-sal-delay="150">
                                            {{app_static.intro.title}}
                                        </h3>
                                        <p class="intro_body_text" data-sal="slide-up" data-sal-duration="800" data-sal-delay="150">
                                            <span v-for="(item, index) in app_static.intro.body" 
                                            :key="index">
                                            {{item}}
                                            </span>
                                        </p>
                                        <div class="callto-action-btn-wrapper"
                                             data-sal="slide-up"
                                             data-sal-duration="800"
                                             data-sal-delay="150">
                                            <a :href="app_static.opensea_genesis" target="_blank" class="btn btn-primary btn-large">
                                                {{app_static.c2a_buy_on_opensea}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End CTA Area -->

    </div>
</template>

<script>
    import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
    import BlogMixin from "@/mixins/BlogMixin";
    import app_static from '@/app_static.env'
    import Layout from "@/components/layouts/Layout";
    export default {
        name: 'IntroBanner',
        props: {
        },
        components: {Layout},
        mixins: [SalScrollAnimationMixin, BlogMixin],
        data() {
            return {
                app_static: app_static,
                // intro_bg: require('@/assets/images/banner/authors_corner.webp'),
            }
        }
    }
</script>