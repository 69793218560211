<template>
    <ul class="mainmenu">
        <li v-for="(item, index) in navItems"
            :class="[item.hasDropdown ? 'has-droupdown has-menu-child-item' : '', item.megamenu ? 'with-megamenu' : '']"
            :key="`nav-item-${index}`">
            <a :href="item.link" class="nav-link its_new header_nav_override">
                {{ item.title }}
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Nav',
    data() {
        return {
            navItems: [
                {
                    link: '#about',
                    title: 'Home',
                    hasDropdown: false,
                    megamenu: false,
                },
                
                {
                    link: '#creators',
                    title: 'Creators',
                    hasDropdown: false,
                    megamenu: false,
                },
                
                {
                    link: '#collection',
                    title: 'Collection',
                    hasDropdown: false,
                    megamenu: false,
                },
                {
                    link: '#collection',
                    title: 'Tokenomics',
                    hasDropdown: false,
                    megamenu: false,
                },
                {
                    link: '#partners',
                    title: 'Partners',
                    hasDropdown: false,
                    megamenu: false,
                },
                {
                    link: '#team',
                    title: 'Team',
                    hasDropdown: false,
                    megamenu: false,
                },
                
                {
                    link: '#joinus',
                    title: 'Join Us',
                    hasDropdown: false,
                    megamenu: false,
                }
            ]
        }
    }
}
</script>