<template>
    <div class="rainbow_background">
        <!-- Start Footer Area -->
        <div class="rn-footer-one rn-section-gap rn-section-gap-override">
            <div class="container ">
                <!-- <div v-show="false" class="row gx-5">
                    
                    <h2>here1</h2>

                    <div class="col-12 col-sm-4 col-md-4">
                        <div class="h4 text-white">
                            {{app_static.footer.newsletter_title}}
                        </div>
                    </div>

                    
                    <div class="col-12 col-sm-6 col-md-4">
                        <div class="input-group">
                            <input type="email"
                                   class="form-control "
                                   placeholder="Your email"
                                   aria-label="Recipient's email"
                                   v-model="formData.email"
                                   >
                            <div class="input-group-append">
                                <button @click.prevent="handleRegister"
                                    :disabled="formData.email.indexOf('@')<0"
                                    class="btn btn-primary btn-outline-secondary" type="button">
                                    {{app_static.footer.mewsletter_submit_btn}}
                                </button>
                            </div>
                        </div>
                        <span v-if="v$.formData.email.$error" class="text-danger mt--2 ml--5">
                            {{  '* required'  }}
                        </span>
                    </div>

                    
                    <div class="col-12 col-sm-2 col-md-4 ">
                        <div class="d-flex justify-content-end">
                        <div class="hamberger">
                            <button class="hamberger-button">
                                <a class="" :href="app_static.twitter_blisspot" target="_blank">
                                    <img class="feather-menu" :src="require(`@/assets/images/icons/discord.webp`)"
                                        alt="discord">
                                </a>
                            </button>
                        </div>

                        <div class="hamberger ml--15">
                            <button class="hamberger-button">
                                <a class="" :href="app_static.twitter_blisspot" target="_blank">
                                    <img class="feather-menu" :src="require(`@/assets/images/icons/twitter.webp`)"
                                        alt="twitter">
                                </a>
                            </button>
                        </div>
                        </div>
                    </div>
                </div> -->

                <div class="row gx-5">
                    <div class="col-12">
                        <div class="widget-content-wrapper app_footer">
                            <div class="h4 text-white">
                                {{app_static.footer.newsletter_title}}
                            </div>
                            <div class="">
                                <div class="input-group">
                                    <input type="email"
                                           class="form-control "
                                           placeholder="Your email"
                                           aria-label="Recipient's email"
                                           v-model="formData.email"
                                           >
                                    <div class="input-group-append ml--5">
                                        <button @click.prevent="handleRegister"
                                            :disabled="formData.email.indexOf('@')<0"
                                            class="btn btn-notify-me-col" type="button">
                                            {{app_static.footer.mewsletter_submit_btn}}
                                        </button>
                                    </div>
                                </div>
                                <span v-if="v$.formData.email.$error" class="btn-notify-me-col mt--2 ml--5">
                                    {{  '* required'  }}
                                </span>

                            </div>
                            
                            
                            <div class="d-flex justify-content-end mt_sm--20">

                                <div class="icon_link_btn">
                                    <a class="" :href="app_static.discord" target="_blank">
                                        <img class="" :src="require(`@/assets/images/icons/discord.webp`)"
                                            alt="discord">
                                    </a>
                                </div>

                                <div class="icon_link_btn ml--15">
                                    <a class="" :href="app_static.twitter" target="_blank">
                                        <img class="" :src="require(`@/assets/images/icons/twitter.webp`)"
                                            alt="twitter">
                                    </a>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Footer Area -->

        <!-- Start Copyright Area -->
        <div class="copy-right-one ptb--20 bg-color--1">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="copyright-left">
                            <span>{{copyRight}}</span>
                            <ul class="privacy">
                                <li>
                                    <a :href="app_static.terms" target="_black">{{app_static.footer.link_terms}}</a>
                                </li>
                                <li>
                                    <a :href="app_static.privacy" target="_black">{{app_static.footer.link_privacy}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="copyright-right">
                            <social-links/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Copyright Area -->

        <div class="mouse-cursor cursor-outer"/>
        <div class="mouse-cursor cursor-inner"/>

        <!-- Start Top To Bottom Area  -->
        <back-to-top @click="scrollTop"/>
        <!-- End Top To Bottom Area  -->
    </div>
</template>

<style>
    .app_footer {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        align-items: center;
    }

    .rn-section-gap-override {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
</style>
<script>
    import BackToTop from '@/components/layouts/footer/BackToTop'
    import Logo from "@/components/logo/Logo";
    import SocialLinks from "@/components/social/SocialLinks";
    import app_static from '@/app_static.env';
    import useVuelidate from '@vuelidate/core'
    import {required, email} from '@vuelidate/validators'
    import utils from "@/libs/utils"
    import api from "@/libs/data_api"

    export default {
        name: 'Footer',
        components: {SocialLinks, Logo, BackToTop},
        props: {
            data: {
                default: null
            }
        },
        validations() {
            return {
                formData: {
                    email: {required, email}
                }
            }
        },
        mounted() {
            // this.v$.$validate();
        },  
        data() {
            return {
                v$: useVuelidate(),
                app_static: app_static,                
                formData: {
                    email: ''
                }
            }
        },

        computed: {
            copyRight() {
                return app_static.footer && app_static.footer.copyright ? app_static.footer.copyright.replace("#year#", (new Date()).getFullYear()) : "© Blisspot Pty Ltd. All rights reserved.";
            }
        }, 
        methods: {

            scrollTop() {
                window.scrollTo(0, 0);
            },

            handleRegister() {
                this.v$.$validate();
                // utils.console.log("handle Register founder: ", this.emailInput);

                if (!this.v$.$error) {
                    api.register.email(this.formData.email, app_static.footer.register_email_type)
                        .then((resp) => {
                            utils.console.log("register founder resp: ", resp);
                            this.$notify({
                                title: "<h2>Success</h2>",
                                text: "<b>Thank you for your interest!  Stay tuned!</b>", 
                                type: 'success',
                                duration: 2000
                            });
                            this.formData.email = "";
                        })
                        .catch((error) => {
                            utils.console.error("author index faild: ", error);
                            this.$notify({
                                title: "<h2>Error</h2>",
                                text: "<b>Somethig went wrong, please try again!</b>", 
                                type: 'error',
                                duration: 3000
                            });
                        });
                }
            }
        }, 

    }
</script>