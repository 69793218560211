<template>
    <router-view/>

    <!-- <register-referred-modal /> -->
    <notifications position="top center" speed="500" width="80%" />
    
</template>

<script>

    import utils from "@/libs/utils"
    import app_static from '@/app_static.env'
    import store from '@/store/index'

    import AppFunctions from './helpers/AppFunctions';
    import * as init_team from "@/libs/init_team";
    import * as init_author from "@/libs/init_author";
    import * as init_course from "@/libs/init_course";
    import * as init_nft from "@/libs/init_nft";
    import * as init_partner from "@/libs/init_partner";


    // import RegisterReferredModal  from '@/components/app/RegisterReferredModal'
    

    export default {
        name: 'Blisspot',
        components: {
            // RegisterReferredModal
        },
        data() {
            return {
                AppFunctions
            }
        },
        mounted() {
            // utils.console.log("app vue mounted"); 

            init_team.init();
            init_author.init();
            init_course.init();
            init_nft.init();
            init_partner.init();

            
        }, 
        beforeMount() {
            // init_team.init();
            this.AppFunctions.addClass('body', 'active-dark-mode');
        },
        watch: {
            '$route': function (value) {
                if (value.name === 'HomePageFive') {
                    this.AppFunctions.addClass('body', 'with-particles');
                } else if (value.name === 'HomePageSeven' || value.name === 'HomePageEight') {
                    this.AppFunctions.addClass('body', 'home-sticky-pin');
                    this.AppFunctions.addClass('body', 'sidebar-header');
                    this.AppFunctions.addClass('body', 'scrollspy-example');
                    this.AppFunctions.addClass('body', 'position-relative');
                } else {
                    const body = document.querySelector('body');

                    if (body.classList.contains('with-particles')) {
                        this.AppFunctions.removeClass('body', 'with-particles');
                    }
                    if (body.classList.contains('home-sticky-pin')) {
                        this.AppFunctions.removeClass('body', 'home-sticky-pin');
                    }
                    if (body.classList.contains('sidebar-header')) {
                        this.AppFunctions.removeClass('body', 'sidebar-header');
                    }
                    if (body.classList.contains('scrollspy-example')) {
                        this.AppFunctions.removeClass('body', 'scrollspy-example');
                    }
                    if (body.classList.contains('position-relative')) {
                        this.AppFunctions.removeClass('body', 'position-relative');
                    }
                }
            }
        },
    }
</script>
