export default
    {
        "data": {
            "response": {
                "cursor": 0,
                "results": [
                    {
                        "avatar_image": "//s3.amazonaws.com/appforest_uf/f1677989860126x146574164701989150/debora.webp",
                        "name_text": "Deborah Tyson",
                        "role_text": "CEO | Founder",
                        "twitter_text": "https://twitter.com/deborahjtyson",
                        "short_desc_text": "30+ years in personal development and wellbeing.   Serial Founder with 2 successful exits.  Mindvalley Expert.  Business Woman of the Year, Counselling, Psychology and Kinesiology.  ",
                        "instagram_text": null,
                        "linkedin_text": "https://www.linkedin.com/in/deborah-tyson/",
                        "tiktok_text": null,
                        "url_text": null,
                        "enabled_boolean": true,
                        "order_number": 1,
                        "type_option_teammembertype": "Team Member",
                        "_id": "1677984681436x825033712420782100"
                    },
                    {
                        "avatar_image": "//s3.amazonaws.com/appforest_uf/f1677984078169x667611670235282600/lie.jpg",
                        "name_text": "Dr Lie Tang",
                        "role_text": "CTO | Partnerships",
                        "twitter_text": "https://twitter.com/LieMingTang",
                        "short_desc_text": "20+ years in tech.  PhD and passion for human-centred technology, Crypto, Defi and other applications of Blockchain.",
                        "instagram_text": null,
                        "linkedin_text": "https://www.linkedin.com/in/liemingtang/",
                        "tiktok_text": null,
                        "url_text": null,
                        "enabled_boolean": true,
                        "order_number": 2,
                        "type_option_teammembertype": "Team Member",
                        "_id": "1677984112119x550952082085511200"
                    },
                    {
                        "avatar_image": "//s3.amazonaws.com/appforest_uf/f1678264848028x227222191527918880/bradley.webp",
                        "name_text": "Bradley Hughes",
                        "role_text": "Growth",
                        "twitter_text": "https://twitter.com/RoaringAuctions",
                        "short_desc_text": "25+ years of digital business development.  Founder of Roaring Auctions, a #cryptoart and #NFT auction house est. January 2017. ",
                        "instagram_text": null,
                        "linkedin_text": "https://www.linkedin.com/in/angelsintheai/",
                        "tiktok_text": null,
                        "url_text": null,
                        "enabled_boolean": true,
                        "order_number": 3,
                        "type_option_teammembertype": "Team Member",
                        "_id": "1677984969439x417509840207216640"
                    },
                    {
                        "avatar_image": "//s3.amazonaws.com/appforest_uf/f1677986369004x948658539554305900/adam.jpg",
                        "name_text": "Adam Caines",
                        "role_text": "Advisor",
                        "twitter_text": null,
                        "short_desc_text": "20+ Years across Product, Delivery, Growth, Customer Experience and Business Ops, with Strong Leadership Experience.  Founder of Upplft and ViiSR.",
                        "instagram_text": null,
                        "linkedin_text": "https://www.linkedin.com/in/adamcaines/",
                        "tiktok_text": null,
                        "url_text": null,
                        "enabled_boolean": true,
                        "order_number": 3.5,
                        "type_option_teammembertype": "Advisor",
                        "_id": "1677986375739x304867093986934800"
                    },
                    {
                        "avatar_image": "//s3.amazonaws.com/appforest_uf/f1677989851573x565741204517862200/chresh.webp",
                        "name_text": "Chreshall Nikhil",
                        "role_text": "Content | Social",
                        "twitter_text": null,
                        "short_desc_text": "Content and social manager, the world's best wellbeing content.  Production at AJD Media, Go Hudson Digital and VR Kingdom. ",
                        "instagram_text": null,
                        "linkedin_text": "https://www.linkedin.com/in/chreshall/",
                        "tiktok_text": null,
                        "url_text": null,
                        "enabled_boolean": true,
                        "order_number": 4,
                        "type_option_teammembertype": "Team Member",
                        "_id": "1677986039474x554653075853803500"
                    },
                    {
                        "avatar_image": "//s3.amazonaws.com/appforest_uf/f1677989842004x276162139088963700/ahmand.webp",
                        "name_text": "Ahmad Raza",
                        "role_text": "Full Stack",
                        "twitter_text": null,
                        "short_desc_text": "App Specialist and Full stack developer specialising in backend, community software.",
                        "instagram_text": null,
                        "linkedin_text": null,
                        "tiktok_text": null,
                        "url_text": null,
                        "enabled_boolean": true,
                        "order_number": 5,
                        "type_option_teammembertype": "Team Member",
                        "_id": "1677986147622x666988583377961000"
                    },
                    {
                        "avatar_image": "//s3.amazonaws.com/appforest_uf/f1678267026622x644775163620299900/bogdan%202%202.png",
                        "name_text": "Bogdan Hutuleac",
                        "role_text": "Senior Engineer",
                        "twitter_text": null,
                        "short_desc_text": "20+ Years, Specialist in UI/UX design, and seamless integrator of innovative technology.",
                        "instagram_text": null,
                        "linkedin_text": "https://www.linkedin.com/in/bogdanhutuleac/",
                        "tiktok_text": null,
                        "url_text": null,
                        "enabled_boolean": true,
                        "order_number": 6,
                        "type_option_teammembertype": "Team Member",
                        "_id": "1677986241222x559594896298606600"
                    },
                    {
                        "avatar_image": "//s3.amazonaws.com/appforest_uf/f1678264991366x583596972205895400/1627574074861%202%20%281%29.png",
                        "name_text": "Debby Limanpro",
                        "role_text": "Business Development Manager",
                        "twitter_text": null,
                        "short_desc_text": "Providing solutions for Organisations, C-suite Executives and Entrepreneurs to get their projects and ideas roaring and soaring!",
                        "instagram_text": null,
                        "linkedin_text": "https://www.linkedin.com/in/debby-liman-a4b75419b/",
                        "tiktok_text": null,
                        "url_text": null,
                        "enabled_boolean": true,
                        "order_number": 7,
                        "type_option_teammembertype": "Team Member",
                        "_id": "1678265104299x712092864648158600"
                    }
                ],
                "count": 8,
                "remaining": 0
            }
        },
        "status": 200,
        "statusText": "OK",
        "headers": {
            "content-type": "application/json"
        },
        "config": {
            "transitional": {
                "silentJSONParsing": true,
                "forcedJSONParsing": true,
                "clarifyTimeoutError": false
            },
            "transformRequest": [
                null
            ],
            "transformResponse": [
                null
            ],
            "timeout": 0,
            "xsrfCookieName": "XSRF-TOKEN",
            "xsrfHeaderName": "X-XSRF-TOKEN",
            "maxContentLength": -1,
            "maxBodyLength": -1,
            "env": {
                "FormData": null
            },
            "headers": {
                "Accept": "application/json, text/plain, */*"
            },
            "baseURL": "https://blisspot.io/api/1.1/obj",
            "withCredentials": false,
            "credentials": false,
            "method": "get",
            "url": "https://blisspot.io/api/1.1/obj/team"
        },
        "request": {}
    }