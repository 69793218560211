import Vue from 'vue'
import Vuex from 'vuex'
import pathify, { make } from 'vuex-pathify'


// Modules
import team from './team'
import author from './author'
import course from './course'
import nft from './nft'
import partner from './partner'
import modalReferred from './modalReferred'

// Vue.use(Vuex)

export default new Vuex.Store({

  // use the plugin
  plugins: [
    pathify.plugin
  ],
  modules: {
    team,
    author,
    course,
    nft,
    partner,
    modalReferred
  },
  strict: process.env.DEV,
})
