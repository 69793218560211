<template>
    <div class="rn-popup-modal placebid-modal-wrapper modal fade" id="regFounderEmailModal" tabindex="-1" aria-hidden="true">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"/></button>
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" 
            data-mdb-backdrop="true"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">{{app_static.founder.modal_title}}</h3>
                </div>
                <div class="modal-body pl--0 pr--0">
                    

                    <div class="placebid-form-box">
                        <div class="bid-content mb--10">
                            <div class="bid-content-top">
                                <div class="bid-content-left">
                                    <!-- <input id="value" type="email" name="value"> -->
                                    
                                    <!-- <input id="founderRegEmailInput"
                                            type="text"
                                           placeholder="Email"
                                           aria-label="Recipient's email"
                                           v-model="formData.email"> -->

                                    <input id="contact-email" name="contact-email" type="email" v-model="formData.email">
                                </div>
                            </div>
                            <span v-if="v$.formData.email.$error" class="text-danger mt-2 d-inline-block">
                                {{ '* ' + v$.formData.email.$errors[0].$message }}
                            </span>

                        </div>
                        <div class="bit-continue-button">
                            <button @click.prevent="handleRegister" class="btn btn-primary w-100" :disabled="formData.email.indexOf('@')<0" data-bs-dismiss="modal">
                                {{app_static.founder.modal_btn}}
                            </button>
                            <button type="button" class="btn btn-primary-alta mt--10" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import app_static from '@/app_static.env'
    import utils from "@/libs/utils"
    import api from "@/libs/data_api"
    import useVuelidate from '@vuelidate/core'
    import {required, email} from '@vuelidate/validators'

    export default {
        name: 'RegFounderEmailModal',
        data() {
            return {
                app_static: app_static,
                v$: useVuelidate(),
                formData: {
                    email: ''
                }
            }
        },
        validations() {
            return {
                formData: {
                    email: {required, email}
                }
            }
        },
        mounted() {
            // this.v$.$validate();
        },  
        methods: {
            handleRegister() {
                this.v$.$validate();
                // utils.console.log("handle Register founder: ", this.emailInput);

                if (!this.v$.$error) {
                    api.register.email(this.formData.email, app_static.founder.register_email_type)
                        .then((resp) => {
                            utils.console.log("register founder resp: ", resp);
                            this.$notify({
                                title: "<h2>Success</h2>",
                                text: "<b>Thank you for your interest!  Stay tuned!</b>", 
                                type: 'success',
                                duration: 2000
                            });
                        })
                        .catch((error) => {
                            utils.console.error("author index faild: ", error);
                            this.$notify({
                                title: "<h2>Error</h2>",
                                text: "<b>Somethig went wrong, please try again!</b>", 
                                type: 'error',
                                duration: 3000
                            });
                        });
                }
            }
        }
    }
</script>