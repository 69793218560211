<template>
    <div id="founder" class="rn-new-items rn-section-gapTop">
        <div class="bg_image--founder bg_image bg-image-border parent_div"
            data-black-overlay="2">
            <!-- <a :href="app_static.opensea_genesis" target="_blank" class="btn btn-primary btn-large mr--30 mb--30">
              {{app_static.founder.c2a_founder_register_interest}}
            </a> -->
            <p class="algin_comingsoon">
                <h1 style="text-align:end">{{ app_static.founder.text_coming_soon }}</h1>
                <h4 style="text-align:end">{{ app_static.founder.text_eta }}</h4>
            </p>
            

            <div class="bottom_div">
                
                <h4 style="text-align:right;" class="text-white c2a_founder_wide">
                    {{ app_static.founder.text_desc }}
                </h4>

                <span style="text-align:right; font-size:20px; font-weight:700;" class="text-white c2a_founder_mobile">
                    {{ app_static.founder.text_desc }}
                </span>

                <button type="button" 
                    class="btn btn-primary btn-large " 
                    data-bs-toggle="modal"
                    data-bs-target="#regFounderEmailModal">
                    {{ app_static.founder.c2a_founder_register_interest }}
                </button>
            </div>
            
        </div>

        <reg-founder-email-modal />
    </div>
</template>

<style lang="scss">



.parent_div
{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: end;

}

.algin_comingsoon {
    margin-top: 30px;
    margin-right: 30px;
}

.bottom_div {
    margin-bottom: 30px;
    margin-right: 30px;
    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: end;
}
</style>

<script>
import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
import BlogMixin from "@/mixins/BlogMixin";
import app_static from '@/app_static.env'
import Layout from "@/components/layouts/Layout";

import RegFounderEmailModal from "@/components/app/RegFounderEmailModal";


export default {
    name: 'FounderBanner',
    props: {
    },
    components: { Layout, RegFounderEmailModal },
    mixins: [SalScrollAnimationMixin, BlogMixin],
    data() {
        return {
            app_static: app_static,
            // banner_img: require('@/assets/images/banner/founding.jpg'),
            // banner_img_mob: require('@/assets/images/banner/founding_square.webp'),
        }
    }
}
</script>