import app_static from '@/app_static.env'

export default {
  console: {
    log: function(...data) {
      if ( app_static.debug ) {
        console.log(data[0], ...data.slice(1))
      }
    },
    error: function(...data) {
      console.error(data[0], ...data.slice(1))
    }
  }, 

  rh: {
    walletProvider: [
      'Etherium',
    ], 
    pendingReferral(rhObj, payload) {      
      // var data = {
      //   crypto_wallet_address: '0x2e78255b2906cbe1f1c8c0314ae566953c019d03',
      //   crypto_wallet_provider: 'Etherium',
      //   name: 'Dev Test',
      //   email: 'newtest1@gmail.com'
      // };
      if (rhObj && payload ) {
        rhObj.pendingReferral(payload);
      }
    }
  }
}