<template>
    <div id="partners" class="slider-one rn-section-gapTop bg-partners">

        <!-- Start Banner Area -->

        <div class="container">

            <div class="row row-reverce-sm align-items-center">
                <div class="col-lg-5 col-md-6 col-sm-12 mt_sm--50">
                    <h2 class="title text-white" data-sal-delay="200" data-sal="" data-sal-duration="800">
                        {{ app_static.partner.title }}
                    </h2>
                    <p class="slide-disc partners_body_text" data-sal-delay="300" data-sal="" data-sal-duration="800">
                        {{ app_static.partner.subtitle }}
                    </p>
                </div>
                <div class="col-lg-5 col-md-6 col-sm-12 offset-lg-1">
                    <div class="slider-thumbnail">
                        <img :src="banner_img" alt="authors">
                    </div>
                </div>
            </div>


            <!-- <div class="d-flex justify-content-around">
                    <div class="">
                        <h2 class="title text-white" data-sal-delay="200" data-sal="" data-sal-duration="800">
                            {{ app_static.partner.title }}
                        </h2>
                        <div class="slide-disc partners_body_text" data-sal-delay="300" data-sal="" data-sal-duration="800">
                            {{ app_static.partner.subtitle }}
                        </div>
                        <div class="button-group mt--10">
                            <a class="btn btn-large btn-primary border-5" 
                                target="_blank" 
                                :href="app_static.partners_landing" data-sal-delay="400" data-sal="slide-up"
                                data-sal-duration="800">
                                {{ app_static.partner.c2a_btn_text }}
                        </a>
                        </div>
                    </div>

                    <div class="">
                        <img style="max-height:450px" :src="banner_img" alt="authors">
                    </div>

                </div> -->
            <!-- <div class="row row-reverce-sm">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mt_sm--50">
                        <h2 class="title text-white" data-sal-delay="200" data-sal="" data-sal-duration="800">
                            {{ app_static.partner.title }}
                        </h2>
                        <div class="slide-disc partners_body_text" data-sal-delay="300" data-sal="" data-sal-duration="800">
                            {{ app_static.partner.subtitle }}
                        </div>
                        <div class="button-group mt--10">
                            <a class="btn btn-large btn-primary border-5" 
                                target="_blank" 
                                :href="app_static.partners_landing" data-sal-delay="400" data-sal="slide-up"
                                data-sal-duration="800">
                                {{ app_static.partner.c2a_btn_text }}
                        </a>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div class="">
                            <img style="max-height:450px" :src="banner_img" alt="authors">
                        </div>
                    </div>
                </div> -->
        </div>

        <div class="container">
            <partner-logos />
        </div>
        <!-- End Banner Area -->
    </div>
</template>

<script>
import SalScrollAnimationMixin from "@/mixins/SalScrollAnimationMixin";
import app_static from '@/app_static.env'
import Layout from "@/components/layouts/Layout";
import PartnerLogos from "@/components/app/PartnerLogos";

export default {
    name: 'Partners',
    props: {
    },
    components: { Layout, PartnerLogos },
    mixins: [SalScrollAnimationMixin],
    data() {
        return {
            app_static: app_static,
            banner_img: require('@/assets/images/banner/wellness_activated.png'),
        }
    }
}
</script>