<template>
    <div :class="`product-style-one ${productStyleClass}`">
        <div class="card-thumbnail">
            <router-link to="">
                <img :src="productDate.avatar_image" :alt="productDate.name_text" @load="$emit('handleImageLoad')">
            </router-link>
            <countdown v-if="productDate.countdown" :date="productDate.countdown.date" />
            <router-link v-if="showPlaceBid" to="" class="btn btn-primary">
                Place Bid
            </router-link>
        </div>
        <div class="product-share-wrapper">
            <div class="team_name">
                {{ productDate.name_text }}
                <!-- <router-link v-for="(author, index) in productDate.authors"
                             :key="`author-${index}`"
                             :to="`/author/${author.id}`"
                             class="avatar"
                             :data-tooltip="author.name">
                    <img :src="author.image" :alt="author.name">
                </router-link>
                <router-link to="#" class="more-author-text">
                    {{ productDate.name_text }}
                </router-link> -->
            </div>

        </div>
        <span class="team_role_text">{{ productDate.role_text }}</span>
        <p class="team_short_desc">{{ productDate.short_desc_text }}</p>
        <!-- <router-link :to="`/product/${productDate.id}`">
            <span class="product-name">{{ productDate.productName }}</span>
        </router-link>
        <span class="latest-bid">{{ productDate.latestBid }}</span> -->
        <!-- <div class="bid-react-area">
            <div class="last-bid">{{ productDate.lastBid }}</div>
            <div class="react-area">
                <i class="feather-heart"/>
                <span class="number">{{ productDate.reacted }}</span>
            </div>
        </div> -->
    </div>
</template>

<script>
import Countdown from '@/components/product/Countdown';

export default {
    name: 'ProductCard',
    components: { Countdown },
    props: {
        productDate: {},
        productStyleClass: {
            type: String
        },
        showPlaceBid: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        '$route': function (to, from) {
            this.closeModal()
        }
    },
    methods: {
        closeModal() {
            const modals = document.querySelectorAll('.modal-backdrop');

            if (modals.length) {
                modals.forEach(item => {
                    item.remove();
                })
            }
        }
    }
}
</script>