/**
 * bubble data api
 */

import endpoints from '@/data_api_endpoints.env'
import axios from "axios";
import app_static from '../app_static.env';
import utils from "./utils"

let cache = {
  author_list: require('@/cache.author_list'),
  course_list: require('@/cache.course_list'),
  nft_list: require('@/cache.nft_list'),
  partner_list: require('@/cache.partner_list'),
  team_list: require('@/cache.team_list')
}


// console.log("cache author list: ", cache.author_list);

var api = {
  authClient: null,
  axiosObj: null,
  config: {
    // required to handle the CSRF token
    withCredentials: false,
    credentials: false,
    headers: {
      // Overwrite Axios's automatically set Content-Type
      // 'Content-Type': 'application/json'
    }
  },
  urls: endpoints,
  fullUrl(url) {
    // utils.console.log("full url: ", this.base_url + url);
    return this.base_url + url;
  },
  // init(axiosObj, url) {
  init(url) {
    this.axiosObj = axios;
    // this.base_url = url ? url : api.base_url;
    // utils.console.log("base url: ", this.base_url);
    // this.newClient(this.base_url);
    this.newClient(api.urls.base_url);

  },
  newClient(url) {
    this.authClient = this.axiosObj.create({
      baseURL: url,
      withCredentials: this.config.withCredentials, // required to handle the CSRF token
      credentials: this.config.credentials,
    });
    return this.authClient;
  },

  ReferralHero: {
    createSubscriber(payload) {
      // var paylaod = {
      //   name: form.querySelector('#name'),
      //   email: form.querySelector('#email'),
      //   phone_number: form.querySelector('#phone_number'),
      //   crypto_wallet_address: form.querySelector('#crypto_wallet_address'),
      //   extra_field: form.querySelector('#country'),
      //   terms: true // Terms and conditions. Mandatory if your campaign  requires the acceptance of T&C
      // };

      if (window.RH) {
        window.RH.form.submit(payload);
      }
    },

    pendingReferral(payload) {
      // var data = {
      //   crypto_wallet_address: '0x2e78255b2906cbe1f1c8c0314ae566953c019d03',
      //   crypto_wallet_provider: 'Etherium',
      //   name: 'Dev Test',
      //   email: 'newtest1@gmail.com'
      // };
      if (window.RH) {
        utils.console.log("ReferralHero pending referrral: "), payload;
        // utils.console.log("sending to ReferralHero");
        window.RH.pendingReferral(payload);
      }
    }
  },

  referred: {

    register: function (payload) {
      return new Promise((resolve, reject) => {
        let url = api.urls.wf.referral_reg;

        utils.console.log("referred register url: ", url, payload);

        api.authClient.post(url, payload)
          .then(function (resp) {
            utils.console.log("referred register resp: ", resp)
            if (resp.status == 200) {
              resolve(resp);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },

  // auth
  // auth: {
  //   login(email, password, remember) {
  //     return new Promise((resolve, reject) => {
  //       let url = api.urls.wf.login;
  //       utils.console.log("login url: ", url);

  //       let payload = {
  //         email: email, 
  //         password: password, 
  //         remember: remember
  //       }

  //       api.authClient.post(url,payload)
  //         .then(function (resp) {
  //           utils.console.log("login resp: ", resp)
  //           if (resp.status == 200) {
  //             resolve(resp);
  //           } else {
  //             reject(resp);
  //           }
  //         })
  //         .catch(error => {
  //           utils.console.log("ERRRR:: ", error.message);
  //           reject(error);
  //         });
  //     });
  //   }
  // }, 

  // partner 
  register: {
    email: function (email, type) {
      return new Promise((resolve, reject) => {
        let url = api.urls.wf.register_email;
        utils.console.log("register_email url: ", url);

        let payload = {
          email: email,
          type: type
        }

        api.authClient.post(url, payload)
          .then(function (resp) {
            // utils.console.log("register_email resp: ", resp)
            if (resp.status == 200) {
              resolve(resp);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },

  // partner 
  partner: {
    index: function () {
      return new Promise((resolve, reject) => {

        if (app_static.use_cache.partner_list) {
          // utils.console.log("return partner cache: ", cache.partner_list);
          resolve(cache.partner_list.default.data.response.data);

        }

        let url = api.urls.wf.partner_list;
        // utils.console.log("partner index url: ", url);
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("partner list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });

      });

    },
  },

  // nfts 
  nft: {
    index: function () {
      return new Promise((resolve, reject) => {


        if (app_static.use_cache.nft_list) {
          // utils.console.log("return nft_list cache: ", cache.nft_list);
          resolve(cache.nft_list.default.data.response.data);

        }


        let url = api.urls.wf.nft_list;
        utils.console.log("nft index url: ", url);
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("nft list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }
          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },

  // course 
  course: {
    index: function () {
      return new Promise((resolve, reject) => {

        if (app_static.use_cache.course_list) {
          // utils.console.log("return course_list cache: ", cache.course_list);
          resolve(cache.course_list.default.data.response.data);
        }

        let url = api.urls.wf.course_list;
        utils.console.log("course index url: ", url);
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("course list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }

          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },

  // author 
  author: {
    index: function () {
      return new Promise((resolve, reject) => {

        if (app_static.use_cache.author_list) {
          // utils.console.log("return author_list cache: ", cache.author_list);
          resolve(cache.author_list.default.data.response.data);
        }

        let url = api.urls.wf.author_list;
        utils.console.log("author index url: ", url);
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("author list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.data);
            } else {
              reject(resp);
            }

          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },


  // team 
  team: {
    index: function () {
      return new Promise((resolve, reject) => {


        if (app_static.use_cache.team_list) {
          // utils.console.log("return team_list cache: ", cache.team_list);
          resolve(cache.team_list.default.data.response.results);
        }


        let url = api.urls.links.team_list;
        api.authClient.get(url)
          .then(function (resp) {
            utils.console.log("team list: ", resp)
            if (resp.status == 200) {
              resolve(resp.data.response.results);
            } else {
              reject(resp);
            }

          })
          .catch(error => {
            utils.console.log("ERRRR:: ", error.message);
            reject(error);
          });
      });
    },
  },



}

export default api;

