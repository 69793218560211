<template>

    <div class="rn-popup-modal placebid-modal-wrapper modal fade" id="regReferredModalId" tabindex="-1" aria-hidden="true" style="z-index:9999999 !important;">

        <button @click="hideModal()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"/></button>

        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" 
            data-mdb-backdrop="true"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">{{app_static.regReferredModal.title}}</h3>
                </div>
                <div class="modal-body pl--0 pr--0">
                    <p class="">
                        {{app_static.regReferredModal.subtitle}}
                    </p>

                    <div class="form-wrapper-one register_referred_modal_form">
                        <form>
                            <div class="mb-5">
                                <label for="name" class="form-label">Full Name</label>
                                <input type="text" id="name" v-model="formData.name">
                            </div>
                            <div class="mb-5">
                                <label for="exampleInputEmail1" class="form-label">Email address</label>
                                <input type="email" id="exampleInputEmail1" v-model="formData.email">
                            </div>

                            <div class="mb-5">
                                <label for="walletProvider" class="form-label">Wallet Provider</label>
                                <select style="min-height: 50px;" id="walletProvider" name="walletProvider" v-model="formData.walletProvider">
                                    <option :value="item" v-for="(item, index) in app_static.regReferredModal.crypto_wallet_providers" >
                                        {{ item }}
                                    </option>
                                </select>
                            </div>

                            <div class="mb-5">
                                <label for="walletaddress" class="form-label">Wallet Address</label>
                                <input type="text" id="walletaddress" v-model="formData.walletAddress"
                                placeholder="0x66ef ...">
                            </div>

                        </form>
                    </div>

                    <div class="placebid-form-box">
                        <div class="bit-continue-button">
                            <button @click.prevent="handleRegister" class="btn btn-primary w-100" :disabled="formData.email.indexOf('@')<0" data-bs-dismiss="modal">
                                {{app_static.founder.modal_btn}}
                            </button>
                            <button @click="hideModal()" type="button" class="btn btn-primary-alta mt--10" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import app_static from '@/app_static.env'
    import utils from "@/libs/utils"
    import api from "@/libs/data_api"
    import useVuelidate from '@vuelidate/core'
    import {required, email} from '@vuelidate/validators'

    export default {
        name: 'RegisterReferredModal',
        data() {
            return {
                app_static: app_static,
                v$: useVuelidate(),
                formData: {
                    name: '',
                    email: '',
                    walletAddress: '',
                    walletProvider: '',
                    referralCode: '', 
                    referralSys: app_static.regReferredModal.referralSys
                }, 
            }
        },

        created() {
            // this.formData.walletProvider = app_static.regReferredModal.crypto_wallet_providers.metamask;
        }, 

        computed: {
            routeQuery() {
                utils.console.log("computed routequery RH_ParamVal: ", this.$route.query[app_static.regReferredModal.RH_url_param]);
                return this.$route.query[app_static.regReferredModal.RH_url_param];
            }
        },
        
        watch: {
            routeQuery(newVal, oldVal) {
                this.handleRouteQuery(newVal);
            }
        },

        validations() {
            return {
                formData: {
                    email: {required, email},
                    walletAddress: {required},
                    referralCode: {required}
                }
            }
        },
        mounted() {
            // this.formData.walletProvider = app_static.regReferredModal.crypto_wallet_providers.metamask;

            // this.v$.$validate();
            if (this.routeQuery ) {
                utils.console.log("modal elem mounted RH_ParamVal route query: ", this.routeQuery);
                this.handleRouteQuery(this.routeQuery);
            }
        },  
        methods: {

            hideModal() {
                let modalElm = document.getElementById('regReferredModalId');                         
                modalElm.classList.remove('show');
                modalElm.classList.remove('d-block');
            }, 

            showModal() {
                let modalElm = document.getElementById('regReferredModalId');                         
                modalElm.classList.add('show');
                modalElm.classList.add('d-block');
            }, 

            handleRouteQuery(RH_ParamVal) {
                if ( RH_ParamVal ) {
                    this.formData.referralCode = RH_ParamVal;

                    let self = this; 
                    setTimeout(function () {
                        // utils.console.log("RH_ParamVal add class show to regReferredModal :", document.getElementById('regReferredModalId'));
                        self.showModal();
                    }, app_static.regReferredModal.delayPopup);
                }
            },  

            handleRegister() {
                this.v$.$validate();
                // utils.console.log("handle Register founder: ", this.emailInput);

                if (!this.v$.$error) {

                    let self = this; 

                    api.ReferralHero.createSubscriber({
                        crypto_wallet_address: this.formData.walletAddress,
                        crypto_wallet_provider: this.formData.walletProvider,
                        name: this.formData.name,
                        email: this.formData.email,
                        phone_number: '',
                        extra_field: '',
                        terms: true
                    });

                    api.referred.register(this.formData)
                        .then((resp) => {
                            utils.console.log("register referred resp: ", resp);
                            this.$notify({
                                title: "<h2>Success</h2>",
                                text: "<b>Thank you for your interest!  Stay tuned!</b>", 
                                type: 'success',
                                duration: 2000
                            });
                        })
                        .catch((error) => {
                            utils.console.error("author index faild: ", error);
                            this.$notify({
                                title: "<h2>Error</h2>",
                                text: "<b>Somethig went wrong, please try again!</b>", 
                                type: 'error',
                                duration: 3000
                            });
                        });
                    
                        setTimeout(function() {
                            api.ReferralHero.pendingReferral({
                                crypto_wallet_address: self.formData.walletAddress,
                                crypto_wallet_provider: self.formData.walletProvider,
                                name: self.formData.name,
                                email: self.formData.email
                            });
                        }, app_static.regReferredModal.delaySendPendingReferral); 
                    
                    this.hideModal();
                }
            }
        }
    }
</script>