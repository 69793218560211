<template>
    <layout>
        <!-- Start Banner Area -->
        <div class="slider-one rn-section-gapTop">
            <div class="container">
                <div class="row row-reverce-sm align-items-center">
                    <div class="col-lg-4 col-md-12 col-sm-12">
                        <h2 class="title" data-sal-delay="200" data-sal="" data-sal-duration="800">
                            {{ app_static.banner_title }}
                        </h2>
                        <p class="slide-disc" data-sal-delay="300" data-sal="" data-sal-duration="800">
                            {{ app_static.banner_subtitle }}
                        </p>
                        <div class="button-group">
                            <a class="btn btn-large btn-primary border-5" target="_blank" :href="app_static.opensea_genesis"
                                data-sal-delay="400" data-sal="slide-up" data-sal-duration="800">
                                {{ app_static.c2a_buy_on_opensea }}
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 offset-lg-1">
                        <div class="slider-thumbnail">
                            <img :src="banner_img" alt="authors">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Banner Area -->

        <!-- video banner Start -->
        <video-banner />
        <!-- video banner End -->

        <!-- intro banner Start -->
        <intro-banner />
        <!-- intro banner End -->

        <!-- author Start -->
        <author />
        <!-- author End -->

        <!-- course Start -->
        <course />
        <!-- course End -->

        <!-- nft Start -->
        <nft />
        <!-- nft End -->

        <!-- nft Start -->
        <founder-banner />
        <!-- nft End -->


        <!-- nft Start -->
        <partner />
        <!-- nft End -->

        <!-- referral-signup Start -->
        <referral-signup />
        <!-- referral-signup End -->


        <!-- team Start -->
        <team />
        <!-- team End -->

        <!-- nft Start -->
        <join-us-banner />
        <!-- nft End -->

        <!-- <share-modal />
        <report-modal /> -->
        <register-referred-modal />

    </layout>
</template>



<script>
import Layout from '@/components/layouts/Layout'
// import ExploreLiveCarousel from '@/components/explore/ExploreLiveCarousel'
// import ServiceCard from '@/components/service/ServiceCard'
// import ProductCard from '@/components/product/ProductCard'
import SalScrollAnimationMixin from '@/mixins/SalScrollAnimationMixin'
// import ShareModal from '@/components/modal/ShareModal'
// import ReportModal from '@/components/modal/ReportModal'
// import ExploreFilter from '@/components/explore/ExploreFilter'
// import ServiceStyleOne from '@/components/service/ServiceStyleOne'
// import SellerStyleOne from '@/components/seller/SellerStyleOne'
// import CollectionStyleOne from '@/components/collection/CollectionStyleOne'
// import ExploreNewestItem from '@/components/explore/ExploreNewestItem'

import app_static from '@/app_static.env'

import VideoBanner from '@/components/app/VideoBanner'
import IntroBanner from '@/components/app/IntroBanner'

import Team from '@/components/app/Team'
import Author from '@/components/app/Author'
import Course from '@/components/app/Course'
import Nft from '@/components/app/Nft'
import FounderBanner from '@/components/app/FounderBanner'
import Partner from '@/components/app/Partner'
import JoinUsBanner from '@/components/app/JoinUsBanner'
import ReferralSignup from '@/components/app/ReferralSignup'

import RegisterReferredModal  from '@/components/app/RegisterReferredModal'
    

// import utils from "@/libs/utils"

export default {
    name: 'Genesis',
    components: {
        // ExploreNewestItem,
        // CollectionStyleOne,
        // SellerStyleOne,
        // ServiceStyleOne,
        // ExploreFilter,
        // ReportModal,
        // ShareModal,
        // ProductCard,
        // ServiceCard,
        // ExploreLiveCarousel,
        Layout,
        Team,
        Author,
        Course,
        Nft,
        VideoBanner,
        IntroBanner,
        FounderBanner,
        Partner,
        JoinUsBanner,
        ReferralSignup,
        RegisterReferredModal
    },

    mounted() {
        // utils.console.log("mounted");
        // init_team.init();
        // document.title = "Genesis";
    },
    data() {
        return {
            lang: "en",
            banner_img: require('@/assets/images/banner/authors_corner.webp'),
            app_static: app_static,
        }
    },
    mixins: [SalScrollAnimationMixin]
}
</script>