<template>
    <div id="courses" class="rn-new-items rn-section-gapTop">
        <div class="container">
            <div class="row mb--50 align-items-center">
                <div class="col-lg-9 col-md-6 col-sm-6 col-12">
                    <h3 class="title mb--0" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        {{app_static.course.title}}
                    </h3>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--15">
                    <div class="view-more-btn text-start text-sm-end">
                        <router-link class="btn-transparent" to="/product" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            VIEW ALL <i class="feather-arrow-right"/>
                        </router-link>
                    </div>
                </div> -->
            </div>
            <div class="row g-5">
                <div v-show="true" class="col-lg-4 col-md-6 col-sm-6 col-12"
                     v-for="(item, index) in members()"
                     :key="'members-item-'+index"
                     data-sal=""
                     :data-sal-delay="150+index"
                     data-sal-duration="800">
                    <course-card
                        :product-date="item"
                        product-style-class="no-overlay"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import ProductCard from '@/components/product/ProductCard'
    import CourseCard  from '@/components/app/CourseCard'
    import store from '@/store/index'
    import data_api from "@/libs/data_api";

    import { get,sync } from "vuex-pathify";
    import app_static from '@/app_static.env'
    
    import utils from "@/libs/utils"
    

    export default {
        name: 'ExploreNewestItem',
        components: {
            // ProductCard,
            CourseCard
        },
        computed: {
            course: sync('course/list'),
        }, 
        mounted() {
        }, 
        methods: {
            
            members() {
                // return this.team;
                // utils.console.log("store course list: ", store.state.course.list);
                if (store.state.course && store.state.course.list 
                && store.state.course.list.length > 0) {
                    // return store.state.course.list;
                    return store.state.course.list;
                } else {
                    return [];
                }
            }
        },
        data() {
            return {
                app_static: app_static,
            }
        }
    }
</script>