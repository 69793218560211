// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var app_static = {
    debug: false,
    env: 'prod',    // prod or dev = dev ponits to /version-test 
    use_cache: {
        author_list: true,
        course_list: true,
        nft_list: true,
        team_list: true,
        partner_list: true,
    },
    banner_title: 'Energize and Empower Teams and Community',
    banner_subtitle: 'Get access to personal transformation resources in Web3 from top global experts in wellbeing. Boost growth, success, and empowerment with our online courses, podcasts, and meditations.',
    // banner_image: '/d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1678346517383x440529461535375170%2Fauthors_corner.png?w=768&h=464&auto=compress&dpr=1.5&fit=max',
    banner_image: 'authors_corner.webp',

    // genesis_banner: 'https://blisspot.io/genesis_banner_v2.mp4', 
    // genesis_banner_mob: 'https://blisspot.io/genesis_banner_mob_v2.mp4', 

    // genesis_banner: 'https://customer-2jo4u9a2zwx7dqbv.cloudflarestream.com/184c60b0050fe3b3d653ff1e9ae90166/watch', 
    // genesis_banner_mob: 'https://customer-2jo4u9a2zwx7dqbv.cloudflarestream.com/117151168c50ea117c0171cf1ff9dc5d/watch', 

    // nft collections
    opensea_genesis: '//opensea.io/collection/blisspot-genesis',

    partners_landing: "//blisspot.io/partners",

    // blisspot socials
    discord: 'https://discord.gg/FxwxuufUEP',
    twitter: 'https://twitter.com/blisspot_com',
    linkedin: 'https://www.linkedin.com/company/blisspot/',
    facebook: 'https://www.facebook.com/blisspot/',
    instagram: 'https://www.instagram.com/blisspot_com/',


    // terms and privacy
    about: 'https://blisspot.com/about',
    terms: 'https://blisspot.com/help/terms',
    privacy: 'https://blisspot.com/pages/privacypolicy',

    // emails
    contact: 'contact@blisspot.com',

    // texts
    c2a_buy_on_opensea: 'Buy on OpenSea',
    c2a_join_blisspot_notify_text: 'Join Blisspot for Exclusive Updates',
    c2a_join_blisspot_notify_btn: 'Notify me',

    // footer texts
    footer: {
        newsletter_title: 'Join Blisspot for Exclusive Updates',
        mewsletter_submit_btn: "Notify Me",
        copyright: '© #year# Blisspot Pty Ltd. All rights reserved.',
        link_terms: 'Terms',
        link_privacy: 'Privacy',
        register_email_type: 'Genesis - newsletter',
    },

    // intro banner
    intro: {
        title: "Introducing Blisspot's [GENESIS] Collection",
        body: [
            "Enjoy unlimited access to world-class wellness content with Blisspot's exclusive Genesis Collection.",
            "Choose from 2000 7-day, 1000 1-month, 500 6-month, or 200 12-month membership passes. As a pass holder, you'll receive exclusive rewards such as whitelisting for our Blisspot Founders Collection launch on June 30th, 2023, and priority airdrops for 6 and 12-month access pass holders.",
            "Join us as an early adopter and a true believer in the Blisspot family. Thank you for your support!"
        ]
    },

    // team sections: 
    author: {
        title: "Celebrating Leading Global Creators",
    },

    // team sections: 
    joinus: {
        title: "Join Us",
        subtitle: "Experience the blissful journey with [GENESIS]. Become one of the pioneers to bring joy to the world."
    },

    // team sections: 
    course: {
        title: "Discover our Most in-Demand Resources",
    },

    // partner sections: 
    partner: {
        title: "Our Partners",
        subtitle: "Are you part of a Web 3, DAO, or NFT community? If so, we invite you to become an official partner of Blisspot and offer instant access to all of your NFT holders! By partnering with Blisspot, you'll be able to provide your community with access to our exclusive wellness resources, helping them to achieve their mental and physical wellbeing goals. So why wait? Join our partnership program today and help spread the gift of wellness to your community!",
        c2a_btn_text: "Become a Partner",
    },

    // team sections: 
    nft: {
        title: "Genesis NFTs",
        tokenomics_title: "Tokenomics",
    },

    // team sections: 
    team: {
        title: "Blisspot Team",
    },

    founder: {
        c2a_founder_register_interest: "Register Your Interest",
        modal_title: "Register for Founding Members Collection Launch!",
        modal_btn: "Register",

        register_email_type: "Genesis - register founders interest",

        text_coming_soon: "Coming Soon!",
        text_eta: "eta 30 June 2023",
        text_desc: "Discover more about this exclusive once-in-a-lifetime opportunity!",

    },
    regReferredModal: {
        RH_url_param: "mwr",
        title: "Register for Rewards",
        subtitle: "Register your contact and wallet details to get a share in rewards when you purchase Blisspot NFTs.",
        delayPopup: 2500, // millisecs
        delaySendPendingReferral: 1500,
        referralSys: "RH", // refers to referral hero
        crypto_wallet_providers: [
            'metamask',
            'phantom',
            'coinbase',
            'ledger',
            'exodus',
            'trezor',
            'myetherwallet',
            'jaxx',
            'guarda',
            'trustwallet',
            'Etherium'
        ]
    }
};

export default app_static; 
