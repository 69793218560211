<template>
    <ul class="social-copyright">
        <li>
            <a :href="app_static.twitter" target="_blank"><i class="feather-twitter"/></a>
        </li>
        <li>
            <a :href="app_static.linkedin" target="_blank"><i class="feather-linkedin"/></a>
        </li>
        <!-- <li>
            <a :href="app_static.discord" target="_blank"><img class="img-responsive" :src="require(`@/assets/images/icons/discord.png`)"
                alt="discord"></a>
        </li> -->
        <li>
            <a :href="app_static.instagram" target="_blank"><i class="feather-instagram"/></a>
        </li>
        <li>
            <a :href="app_static.facebook" target="_blank"><i class="feather-facebook"/></a>
        </li>
        <li>
            <a :href="'mailto:' + app_static.contact" target="_blank"><i class="feather-mail"/></a>
        </li>
    </ul>
</template>

<script>
    import app_static from '@/app_static.env'
    export default {
        name: 'SocialLinks',
        data() {
            return {
                app_static: app_static,
            }
        },
    }
</script>