import { createRouter, createWebHistory } from 'vue-router'
import app_static from '../app_static.env';
import utils from "@/libs/utils"

import Genesis from '@/views/home/Genesis'
import CreatorSigup from '@/views/home/CreatorSignup'

const routes = [
    {
        path: '/',
        name: 'Genesis',
        component: Genesis,
        meta: {
            title: 'Blisspot | Genesis',
        },
    },
    {
        path: '/creator',
        name: 'Creator',
        component: CreatorSigup,
        meta: {
            title: 'Genesis | Creator',
        },
    },

    {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "Genesis",
        component: Genesis,
        meta: {
          requiresAuth: false, 
          title: 'Blisspot | Genesis'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // utils.console.log("before each document title set : ", to);
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
