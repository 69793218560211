<template>
    <div  id="joinus" class="rn-new-items rn-section-gapTop">
        <div class="bg_image--joinus bg_image bg-image-border d-flex align-items-center justify-content-end " data-black-overlay="4">
          <div class="call-to-action-wrapper">
            <h1 class="joinus_title_text">
                {{app_static.joinus.title}}
            </h1>
            <p class="joinus_body_text" >
              {{app_static.joinus.subtitle}}
            </p>
            <a :href="app_static.opensea_genesis" target="_blank" class="btn btn-primary btn-large">
              {{app_static.c2a_buy_on_opensea}}
            </a>

        </div>
          
            <!-- <a :href="app_static.opensea_genesis" target="_blank" class="btn btn-primary btn-large mr--30 mb--30">
              {{app_static.c2a_buy_on_opensea}}
            </a> -->
        </div>
        </div>

</template>

<style lang="scss">
.align_c2a_button {
    position: relative;
    bottom: 40px;
    right: 40px;
}
</style>

<script>
    import app_static from '@/app_static.env'
    // import Layout from "@/components/layouts/Layout";
    export default {
        name: 'JoinusBanner',
        props: {
        },
        components: {},
        mixins: [],
        data() {
            return {
                app_static: app_static,
            }
        }
    }
</script>