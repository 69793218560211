export default
    {
        "data": {
            "status": "success",
            "response": {
                "data": [
                    {
                        "is_nft_partner_boolean": true,
                        "Modified Date": 1679542258283,
                        "Created Date": 1679466235846,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1679466218068x332163247863828350/Logo%20and%20Word%20-%20Transparent.webp",
                        "name_text": "Aus DeFi Heroes",
                        "url_text": "https://tinyurl.com/AusDeFi-NFT-Heroes",
                        "discord_text": "https://discord.gg/ZRCTDdsVEF",
                        "facebook_text": "https://www.facebook.com/groups/667667734641098",
                        "twitter_text": "https://twitter.com/AusDeFi",
                        "collection_urls_list_text": [
                            "https://tinyurl.com/AusDeFi-NFT-Heroes"
                        ],
                        "instagram_text": "https://www.instagram.com/aus.defi.association/",
                        "status_option_partnerstatus": "active",
                        "_id": "1679466234369x218497043900661760"
                    },
                    {
                        "is_nft_partner_boolean": false,
                        "Modified Date": 1678345997962,
                        "Created Date": 1677913596731,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1678345996927x362639793777914240/Bit%20Simply%201%201.png",
                        "name_text": "Bitsimply Australia",
                        "url_text": "https://bitsimply.com.au",
                        "collection_urls_list_text": [],
                        "status_option_partnerstatus": "active",
                        "_id": "1677913597115x274987951875686400"
                    },
                    {
                        "is_nft_partner_boolean": false,
                        "Modified Date": 1678345982391,
                        "Created Date": 1677915040977,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1678345981557x716623167675420700/logo-digivizer%202%201.png",
                        "name_text": "Digivizer",
                        "url_text": "https://digivizer.com/",
                        "facebook_text": "https://www.facebook.com/Digivizer/",
                        "twitter_text": "https://twitter.com/digivizer",
                        "collection_urls_list_text": [],
                        "instagram_text": "https://www.instagram.com/digivizer/",
                        "status_option_partnerstatus": "active",
                        "_id": "1677915041365x834255868891234300"
                    },
                    {
                        "is_nft_partner_boolean": false,
                        "Modified Date": 1679542382230,
                        "Created Date": 1677915139125,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1678345975433x590598963359055900/Group%202912%201.png",
                        "name_text": "Fingerprint for Success",
                        "url_text": "https://www.fingerprintforsuccess.com/",
                        "collection_urls_list_text": [],
                        "website_text": "https://www.fingerprintforsuccess.com/",
                        "status_option_partnerstatus": "active",
                        "_id": "1677915139539x827429533411442700"
                    },
                    {
                        "is_nft_partner_boolean": false,
                        "Modified Date": 1678345992232,
                        "Created Date": 1677914604164,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1678345991398x672286758114364500/Go1-Logo%20copy%201%202.png",
                        "name_text": "go1",
                        "url_text": "https://www.go1.com/",
                        "collection_urls_list_text": [],
                        "status_option_partnerstatus": "active",
                        "_id": "1677914603715x599476265751674900"
                    },
                    {
                        "is_nft_partner_boolean": true,
                        "Modified Date": 1678853427463,
                        "Created Date": 1677718616560,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1678346012806x651841692843548700/Roaring%20auction%201%201.png",
                        "name_text": "Roaring Auctions",
                        "url_text": "https://roaringauctions.com/",
                        "facebook_text": "https://www.facebook.com/RoaringAuctions",
                        "twitter_text": "https://twitter.com/RoaringAuctions",
                        "collection_urls_list_text": [
                            "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/36485352290953266050282894270823874314655174234812269052970677832812289065959"
                        ],
                        "status_option_partnerstatus": "active",
                        "telegram_text": "https://t.me/wthauctionhouse",
                        "_id": "1677718615251x660910931515539500"
                    },
                    {
                        "is_nft_partner_boolean": false,
                        "Modified Date": 1679542466153,
                        "Created Date": 1677913386253,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1678346004093x316327920087429800/Upplft%20copy%201%201.png",
                        "name_text": "UPPLFT",
                        "url_text": "https://www.upplft.com/",
                        "facebook_text": "https://www.facebook.com/Upplft/",
                        "twitter_text": "https://twitter.com/upplft",
                        "collection_urls_list_text": [],
                        "website_text": "https://www.upplft.com/",
                        "instagram_text": "https://www.instagram.com/upp_lft/",
                        "status_option_partnerstatus": "active",
                        "_id": "1677913386642x396415272321024000"
                    },
                    {
                        "is_nft_partner_boolean": false,
                        "Modified Date": 1679542485357,
                        "Created Date": 1677913243353,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1678346009362x141375125018401660/viisr_logo%201%201.png",
                        "name_text": "VIISR",
                        "url_text": "https://www.viisr.com/",
                        "facebook_text": "https://www.facebook.com/viisrau",
                        "collection_urls_list_text": [],
                        "website_text": "https://www.viisr.com/",
                        "instagram_text": "https://www.instagram.com/viis.r/",
                        "status_option_partnerstatus": "active",
                        "_id": "1677913242476x481497941266137100"
                    },
                    {
                        "is_nft_partner_boolean": false,
                        "Modified Date": 1679542445531,
                        "Created Date": 1677914925536,
                        "Created By": "1667793692958x750031559396007800",
                        "logo_image": "//s3.amazonaws.com/appforest_uf/f1678345986555x750508798742550300/WORK180-logo%201%201.png",
                        "name_text": "Work 180",
                        "url_text": "https://work180.com",
                        "facebook_text": "https://www.facebook.com/work180official",
                        "twitter_text": "https://twitter.com/work180_",
                        "collection_urls_list_text": [],
                        "website_text": "https://work180.com",
                        "instagram_text": "https://www.instagram.com/_work180/",
                        "status_option_partnerstatus": "active",
                        "_id": "1677914925931x898736902614024200"
                    }
                ]
            }
        },
        "status": 200,
        "statusText": "OK",
        "headers": {
            "content-type": "application/json"
        },
        "config": {
            "transitional": {
                "silentJSONParsing": true,
                "forcedJSONParsing": true,
                "clarifyTimeoutError": false
            },
            "transformRequest": [
                null
            ],
            "transformResponse": [
                null
            ],
            "timeout": 0,
            "xsrfCookieName": "XSRF-TOKEN",
            "xsrfHeaderName": "X-XSRF-TOKEN",
            "maxContentLength": -1,
            "maxBodyLength": -1,
            "env": {
                "FormData": null
            },
            "headers": {
                "Accept": "application/json, text/plain, */*"
            },
            "baseURL": "https://blisspot.io/api/1.1/obj",
            "withCredentials": false,
            "credentials": false,
            "method": "get",
            "url": "https://blisspot.io/api/1.1/wf/partners-genesis"
        },
        "request": {}
    }