// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import app_static from '@/app_static.env'
const urlRoot = 'https://blisspot.io'

let testRoute = '/version-test';
if (app_static.env == "prod") {
  testRoute = "";
}

const baseUrl = urlRoot +testRoute+ "/api/1.1/obj";
const baseWfUrl = urlRoot +testRoute+ "/api/1.1/wf";

// const webAppUrl = urlRoot+testRoute; 
// const baseUrl = "https://blisspot.app/version-test/api/1.1/obj";
// const baseWfUrl = 'https://blisspot.app/version-test/api/1.1/wf';

var data_endpoints = {
  base_url: baseUrl,
  wf_url: baseWfUrl, 
  
  links: {
          // team // note: id = unique id in bubble database.
      team_list: baseUrl + "/team",
      team_member: baseUrl + "/team/{id}",
  }, 
  
  wf: {
    author_list: baseWfUrl+ "/authors-genesis",
    course_list: baseWfUrl + '/courses-genesis',
    nft_list: baseWfUrl + '/nfts-genesis',
    partner_list: baseWfUrl + '/partners-genesis',
    register_email: baseWfUrl+'/register-email',
    // login: baseWfUrl+'/api_login' 
    referral_reg: baseWfUrl + '/api_referral_reg'
  }

  //nfts  
  // nfts_index: "/api/nfts",
  // nfts_show: "/api/nfts/{id}",

};

export default data_endpoints; 
