<template>
  <div class="mt--40 ">
    <!-- <div id="referralhero-signup-widget" class="force_show_referral"></div> -->
    <div id="signup-form"></div>

  </div>
</template>

<style>
.force_show_referral {
  display: block !important;
}
 
</style>

<script>

import app_static from '@/app_static.env'
import utils from "@/libs/utils"

export default {
  name: 'ReferralSignup',
  props: {
  },
  components: {},
  mounted() {
    if (window.RH) {

    //   utils.console.log("yes RH, gen form");
    //   var form = window.RH.generate.form();
    //   var div = document.getElementById("signup-form");
    //   div.appendChild(form) 
    // 
  
    window.RHConfig = {
      callbacks: {
        onLoad: function() {
          // ... your logic ...
        },
        ready: function() {
          // ... your logic ...
          utils.console.log("RH Ready");
            var form = window.RH.generate.form();
            var div = document.getElementById("signup-form");
            div.appendChild(form) 
        },
        beforeSubmit: function(data) {
        },
        success: function(output) {
          // ... your logic ...
          
        },
        afterSuccess: function(output) {
          // ... your logic ...
        },
        error: function() {
          // ... your logic ...
        },
        popupOpen: function() {
          // ... your logic ...
        },
        popupClose: function() {
          // ... your logic ...
        },
        subscriberNotFound: function() {
          // ... your logic ...
        },
        emailNotValid: function(reason) {
          // ... your logic ...
        },
        serverProblem: function(reason) {
          // ... your logic ...
        },
        subscriberLoaded: function(response, data) {
          // ... your logic ...
        }
      }
    }
    } else {
      utils.console.log("no RH");
    }



  }, 
  data() {
    return {
    }
  }
}
</script>