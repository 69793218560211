import data_api from "./data_api";
import store from '@/store/index'
import utils from "./utils"

data_api.init();

export const init = () => {
    data_api.partner.index()
    .then((resp) => {
      // utils.console.log("partner index resp: ", resp);
      store.set("partner/list", resp);
      // utils.console.log("store partner list fater init: ", store.state.partner.list);
    })
    .catch((error) => {
      console.error("partner index faild: ", error)
    });

}

