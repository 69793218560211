<template>
    <div :class="`product-style-one ${productStyleClass}`">
        <div class="card-thumbnail">
            <router-link to="#">
                <img :src="productDate.profile_image" :alt="productDate.first_text + ' ' + productDate.last_text" @load="$emit('handleImageLoad')">
            </router-link>
        </div>
        <div class="product-share-wrapper">
            <div class="team_name">
                {{ productDate.first_text + ' ' + productDate.last_text }}
            </div>

        </div>
        <!-- <span class="team_role_text">{{ productDate.role_text }}</span> -->
        <p class="team_short_desc">{{ productDate.featured_desc_text }}</p>
    </div>
</template>

<script>
    import Countdown from '@/components/product/Countdown';

    export default {
        name: 'ProductCard',
        components: {Countdown},
        props: {
            productDate: {},
            productStyleClass: {
                type: String
            },
            showPlaceBid: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            '$route': function (to, from) {
                this.closeModal()
            }
        },
        methods: {
            closeModal() {
                const modals = document.querySelectorAll('.modal-backdrop');

                if (modals.length) {
                    modals.forEach(item => {
                        item.remove();
                    })
                }
            }
        }
    }
</script>